const data = [
  {
    src: "https://archdesignsstudio.com/assets/projects-re/cavokaviation/1.png",
    text: "1",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/cavokaviation/2.png",
    text: "2",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/cavokaviation/3.png",
    text: "3",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/cavokaviation/4.png",
    text: "4",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/cavokaviation/5.png",
    text: "5",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/cavokaviation/6.png",
    text: "6",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/cavokaviation/7.png",
    text: "7",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/cavokaviation/8.png",
    text: "8",
  },
];

export default data;
