import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Cavok Aviation",
  address: "Navi Mumbai - Panvel",
  client: "Cavok Aviation Simulators",
  area: "40,000 Sq. Ft.",
  status: "Under Construction",
  project: "An institutional campus providing simulator traning for pilots",
  category: "Institutions | Architecture",
  backlink: "/projects/institutions/",
};

export default class Projectcavokaviation extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Institutional Projects | Cavok Aviation Simulators"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="View the groundbreaking design of a lush institutional campus spread over 40,000 sq. ft and providing simulator training for pilots."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
